export const UNAUTHORIZED = 401;
export const UNKNOWNERROR = 449;
export const FAIL_STATUS_CODES = [401, 403];
export const CHANGE_USER_DATA = "CHANGE_USER_DATA";
export const UPLOAD_IMAGE = "UPLOAD_IMAGE";
export const SIGN_DECLARATION = "SIGN_DECLARATION";

// WEB AUTHENTICATION (FIDO) VARIABLES
export const WEB_AUTH_URL = "https://dev.fido.ashieldhub.com/passwordless/";
export const CUS_HEADER = {};
export const WEB_AUTH_ALG = "SHA-256";
export const WEB_AUTH_MID = "024";
// export const WEB_AUTH_MER_TXN_ID = "12345"
export const WEB_AUTH_MER_TXN_ID = new Date().getTime().toString();
